.Tabs{
    float: left;
    width: 12%;
    height: 93vh;
    text-align: left;
    margin: 2px;

}
.bp3-tab-list{
width: 100%;
}
.Container{
    display: flex;
}
.MuiDrawer-paper{
    z-index: -1 !important;
}
.links{
    margin-top:50px;
}
.MuiListItemText-primary{
    color: #757575;
}
.MuiListItemIcon-root {
    min-width: 40px !important;
}
.pageContent{
    margin-top: 60px;
    float: left;
    overflow: hidden;
    height: 90vh;
}
.listItem{
    margin-top: 20px !important;
}
.avatar{
    width: 20px !important;
    height: 20px !important;
    font-size: 10px !important;
    background-color: #295c74 !important;
}
.patients{
    display: flex;
}
.patientList{
    min-width: 250px;
    width:15vw;
}
.patientInfo{
    min-width: 250px;
    margin: 5px;
}
.patientPanel{
    margin-top: 10px;
    font-family: 'Roboto';
   
}
.patientsListItems{
    overflow-y: auto;
    max-height: 68vh;
}
.expandedIcon{
color: black;
}
.MuiExpansionPanelDetails-root{
    display: block !important ;   
    padding: 0 !important;
}
.MuiExpansionPanel-rounded{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  
}
.patientName{
    margin-left: 5px;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    
}
.patientDivider{
    margin-left: 15px !important;
    min-height: 91vh;
}
.Field{
    margin: 5px !important;
}
.TimeStamp{
    margin-top: 10px;
    float: left;
    font-size: 13px;
    margin-right: 10px;
}
.EditCard{
    padding-bottom: 0px !important;
    margin-bottom: 10px !important;
    float: right;

}
.block{
    display: block !important
}
.marginLeft{
    margin-left: 50px !important;
}
.Card-parent{
    padding-bottom: 0 !important;
}
.cardContent{
    display: flex;
    text-align: left;
    justify-content: stretch;
}
.addPatientButton{
    color: white !important;
    width: 100%;

}
.white{
    color: rgb(238, 238, 238) !important;
}
#form-dialog-title > h2{
  color: #251919 !important;  
}
.flexDiv{
    display: flex !important;
    overflow: hidden;
}
.flexChild{
    margin: 10px;
}
.picker{
    min-width: 150px !important;
}
.MuiFormControl-root {
    vertical-align: baseline !important;
}
.genderGroup{
    margin-top: 8px !important;
    margin-left: 81px !important;
    float: right;
}
.patientOptions{
    margin: 5px;
    margin-top: 20px;
    margin-right: 0px;
}
.InsideDivider{
    height: 620px !important;
    margin: 0px !important;
    margin-top: 12px !important;
}
.rightDrawer{
    right: 0;
    border-left: 1px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
    background-color: white;
    width: 250px;
    border-color: rgb(196, 196, 196);
    border-style: solid;
    height: 100%;
    position: fixed;
    margin-top: -15px;
    z-index: 3;
    box-shadow: GREY 8px 0 8px 8px !important;
}

.toothDrawer{
    right: 0;
    border-left: 1px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
    background-color: white;
    width: 250px;
    border-color: rgb(196, 196, 196);
    border-style: solid;
    height: 100%;
    position: fixed;
    margin-top: -15px;
    padding-top: 30px;
    z-index: 4;
    box-shadow: GREY 8px 0 8px 8px !important;
}

.tableComponent{
    margin-top: 20px;
    width: 100%;
    overflow: auto ;
}
.tableRow{
    cursor: pointer;
}
a{text-decoration: none !important} 

.cardContent-input{
width: 47%;
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(60, 28, 39, 0.08) !important;
}

.productName span{
    color: rgb(234 207 14);
}

.productName {
    display: inline-block;
    vertical-align: bottom;
    padding-left: 5px;
    font-family: 'Inter', 'system-ui'!important;
    color: #eeeeee;
}

.chip{
    background-color: #EACF0e !important;
    margin: 0;
    padding: 0;
}
.doctorSelector{
   margin: 10px !important;
}
.pagination{
    position: fixed;
    bottom: 10px;
}

.FreePlanHeader{
    background: linear-gradient(115deg, rgb(56, 178, 172) 0%, rgb(129, 230, 217) 100%);
}

.YearlyPlanHeader{
    background: linear-gradient(115deg, rgb(245, 101, 101) 0%, rgb(254, 178, 178) 100%);
}

.PlanCard{
    display: flex;
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
    flex-direction: column;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem;
    border-top-width: 2px;
    border-bottom-width: 2px;
    flex: 1 1 0%;
}

.QuestionsCard{
    
    z-index: 200;
}
.Questions{      
    color: rgb(45, 55, 72);
}

.Answers{
    font-family: Inter, system-ui;
    color: rgb(36, 62, 99);
    text-align: left;
}

.Footer{
    
    background-color:#2D3748;
}

.FooterText{
    font-family: 'Roboto';
    margin-bottom: -10px !important;
}