body {
    background: white;
}

.spots polygon, .spots path {
    -webkit-transition: fill .25s;
    transition: fill .25s;
}
.spots polygon:hover, .spots polygon:active, .spots path:hover, .spots path:active {
    fill: #b6e0ee !important;
}
