.Card{
    display: block;
    width:  0%;
    min-height: 20vh;
    min-width: 300px;
    margin: auto;
    margin-top: 10%;
    text-align: left;
  }
  .Login{
      width: 100%;
  }
  .image{
    position: absolute;
    opacity: 0.5;
    top: 50px;
    left: 20px;
    z-index: -100;
  }

  .loginImage{
    position: absolute;
    opacity: 0.5;
    top: 120px;
    left: -80px;
    z-index: -100;
  }
  .fields{
     margin-top: 20px;
     margin-bottom: 0px;
     padding-left: 5%;
  }
  .toggle{
      display: block;
      padding-top: 30px;
      padding-left: 15%;
    
  }
  h1 , h2,  h3 ,h4 ,h5 ,h6, span{
    font-family: 'Roboto';
    color: rgb(31, 31, 32) ;
  }
  .link{
   margin: 10px;
   font-weight: 300;
  }
  a :hover{
    cursor: pointer !important;  
  }
  .linkButton{
      font-size: 12px !important;
  }
  .toggle_login{
      padding-left: 30px;;
      margin-top: 25px;
  }
  .fields_login{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5%;
  }

  