.MuiToolbar-regular{
  min-height:20px !important;
}

@media screen and (max-width: 1020px) {
  #content-mobile {display: block;}
  
  #content-mobile-nav {padding-left: 22vw;}
}


.Menu{
  right: 0;
    position: absolute;
}

.appBar{
  min-height: 48px;
}

.NavIcon{
width: 35px;
height: 30px;
display: inline-block;
padding-top: 2px;
padding-left: 10px;
}

.Title{
  font-family: Inter, system-ui;
    color: rgb(36, 62, 99)!important;
    overflow: hidden;     
    margin: 0;
}


.Heading{
  font-size: 1.02rem!important;
  font-family: Inter, system-ui;
  color: rgb(100, 21, 255)!important;
  letter-spacing: 0.1em!important;
  font-weight: 700!important;
  text-transform: uppercase!important;
}

.StyledTitle{
  color: rgb(100, 21, 255)!important;
  font-family: Inter, system-ui!important
}

.Description{
  color: rgb(124, 139, 161)!important;
  letter-spacing: 0.025em;
}

