body {
    background: white;
}

#Spots polygon, #Spots path {
    -webkit-transition: fill .25s;
    transition: fill .25s;
}
#Spots polygon:hover, #Spots polygon:active, #Spots path:hover, #Spots path:active {
    fill: #b6e0ee !important;
}

